var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.messages),function(message,id){return _c('div',{key:id,staticClass:"message",class:{ own: message.userid == message.chatid }},[(
        id == 0 ||
          (id > 0 && _vm.messages[id - 1].message_date != message.message_date)
      )?_c('div',{staticClass:"msgdate justify-center my-3"},[_vm._v(" "+_vm._s(message.message_date)+" ")]):_vm._e(),(
        id == 0 ||
          (id > 0 && _vm.messages[id - 1].username != message.username) ||
          (id > 0 && _vm.messages[id - 1].message_date != message.message_date)
      )?_c('div',{staticClass:"username"},[_vm._v(" "+_vm._s(message.username)+" ")]):_vm._e(),(
        id == 0 ||
          (id > 0 && _vm.messages[id - 1].username != message.username) ||
          (id > 0 && _vm.messages[id - 1].message_time != message.message_time)
      )?_c('div',{staticClass:"msgtime"},[_vm._v(" "+_vm._s(message.message_time)+" ")]):_vm._e(),_c('div',{staticClass:"content mb-1"},[_c('div',{domProps:{"innerHTML":_vm._s(message.chat_message)}}),(message.image)?_c('chat-image',{attrs:{"imgsrc":message.image},on:{"imageLoad":_vm.imageLoad}}):_vm._e()],1)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }