<template>
  <div>
    <div
      class="message"
      v-for="(message, id) in messages"
      v-bind:key="id"
      :class="{ own: message.userid == message.chatid }"
    >
      <div
        class="msgdate justify-center my-3"
        v-if="
          id == 0 ||
            (id > 0 && messages[id - 1].message_date != message.message_date)
        "
      >
        {{ message.message_date }}
      </div>

      <div
        class="username"
        v-if="
          id == 0 ||
            (id > 0 && messages[id - 1].username != message.username) ||
            (id > 0 && messages[id - 1].message_date != message.message_date)
        "
      >
        {{ message.username }}
      </div>
      <div
        class="msgtime"
        v-if="
          id == 0 ||
            (id > 0 && messages[id - 1].username != message.username) ||
            (id > 0 && messages[id - 1].message_time != message.message_time)
        "
      >
        {{ message.message_time }}
      </div>
      <div class="content mb-1">
        <div v-html="message.chat_message"></div>
        <chat-image
          v-if="message.image"
          :imgsrc="message.image"
          @imageLoad="imageLoad"
        ></chat-image>
      </div>
    </div>
  </div>
</template>

<script>
import Image from "./Image.vue";

export default {
  data() {
    return {};
  },
  props: ["messages"],
  components: {
    "chat-image": Image,
  },
  computed: {
    username() {
      return this.$store.getters.user.username;
    },
  },
  methods: {
    imageLoad() {
      // this.$emit('imageLoad')
    },
  },
};
</script>

<style>
span.emoji {
  font-size: 20px;
  vertical-align: middle;
  line-height: 2;
}
</style>
