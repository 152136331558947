<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col sm="12" cols="12" style="position: relative;">
        <div class="chat-container" v-on:scroll="onScroll" ref="chatContainer">
          <div class="loading" v-if="loading">
            <img width="80px" max-width="80px" src="../../assets/loading.gif" />
          </div>
          <message :messages="messages" @imageLoad="scrollToEnd"></message>
          <VEmojiPicker v-show="emojiPanel" @select="addEmojiToMessage" />
        </div>
        <div class="typer">
          <!--<v-btn icon justify-right class="grey--text text--darken-2" @click="showCamera">
            <v-icon>mdi-camera</v-icon>
          </v-btn>
          <v-btn
            icon
            justify-right
            class="orange--text emojiBtn"
            @click="toggleEmojiPanel"
          >
            <v-icon>mdi-emoticon-outline</v-icon>
          </v-btn>-->
          <input
            type="text"
            justify-right
            placeholder="Type here ..."
            v-on:keyup.enter="sendMessage"
            v-model="messageContent"
            @click="emojiPanel = false"
          />
        </div>
      </v-col>
    </v-row>
    <v-layout row v-if="message.text">
      <v-flex xs12 sm6 px-1 ma-0>
        <app-alert
          :textAlert="message.text"
          :typeAlert="message.type"
          @dismissed="onDismissed()"
        ></app-alert>
      </v-flex>
    </v-layout>    
  </v-container>
</template>

<script>
import Message from "./parts/Message.vue";
import VEmojiPicker, { emojisDefault } from "v-emoji-picker";
import axios from "axios";
import moment from "moment";

//import * as firebase from 'firebase'

export default {
  data() {
    return {
      messageContent: "",
      chatMessages: [],
      emojiPanel: false,
      emojis: [],
      limit: 0,
      loading: true,
      totalChatHeight: 0,
    };
  },
  mounted() {
    this.$store.dispatch("clearMessage");
    this.loadChat();
    this.emojis = emojisDefault;
  },
  components: {
    message: Message,
    VEmojiPicker,
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    domain() {
      return this.$store.getters.domain;
    },
    auth_token() {
      return this.$store.getters.token;
    },
    message() {
      return this.$store.getters.message;
    },
    messages() {
      return this.chatMessages;
    },
    onNewMessageAdded() {
      const that = this;
      let onNewMessageAdded = function(message, newMessage = true) {
        if (!newMessage) {
          that.chatMessages.unshift(that.processMessage(message, newMessage));
          that.scrollTo();
        } else {
          that.chatMessages.push(that.processMessage(message, newMessage));
          that.scrollToEnd();
        }
      };
      return onNewMessageAdded;
    },
  },
  methods: {
    loadChat() {
      this.totalChatHeight = this.$refs.chatContainer.scrollHeight;
      const that = this;
      axios
        .post(this.domain + "/api/getChatMessages", {
          auth_token: this.auth_token,
          id: this.user.userid,
          limit: this.limit,
        })
        .then((response) => {
          if (response.data.status != "Success") {
            this.$store.dispatch("setMessage", {
              type: "error",
              text: "Can't fetch messages or no messages found",
            });
          } else {
            that.chatMessages = response.data.results;
          }
          this.loading = false;
          this.scrollToEnd();
        })
        .catch((error) => {
          //message send error
          console.log(error);
        });
    },
    sendMessage() {
      if (this.messageContent !== "") {
        const that = this;
        let message = {
          id: this.user.userid,
          user: this.user.username,
          chat_message: this.messageContent,
          date: moment().format(),
          auth_token: this.auth_token,
        };
        that.onNewMessageAdded(message);

        axios
          .post(this.domain + "/api/saveChatMessage", message)
          .then((response) => {
            //message sent
            if (response.data.status != "Success") {
              this.$store.dispatch("setMessage", {
                type: "error",
                text: "Message not sent",
              });
            } else if (response.data.results.message_saved == false) {
              this.$store.dispatch("setMessage", {
                type: "error",
                text: "Message not saved",
              });
            } else {
              this.loadChat();
            }
          })
          .catch((error) => {
            //message send error
            console.log(error);
          });
        this.limit = 0;
        this.messageContent = "";
      }
    },
    processMessage(message, newMessage) {
      /*eslint-disable */
      var imageRegex = /([^\s\']+).(?:jpg|jpeg|gif|png)/i;
      /*eslint-enable */
      if (imageRegex.test(message.chat_message)) {
        message.image = imageRegex.exec(message.chat_message)[0];
      }
      /*eslint-disable */
      var urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      /*eslint-enable */
      message.chat_message = message.chat_message
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
      message.chat_message = message.chat_message.replace(
        urlPattern,
        "<a href='$1'>$1</a>"
      );
      //replace emojis
      //alert(newMessage);
      if (newMessage == true) {
        //emoji.data with emoji.aliases
        message.chat_message = message.chat_message.replace("🙂", "::smiley::");
        console.log(message);
      } else {
        //emoji.aliases with emoji.data
        message.chat_message = message.chat_message.replace("::smiley::", "🙂");
        console.log(message);
      }
      return message;
    },
    onScroll() {
      let scrollValue = this.$refs.chatContainer.scrollTop;
      const that = this;
      if (scrollValue < 100 && !this.loading) {
        this.totalChatHeight = this.$refs.chatContainer.scrollHeight;
        this.loading = true;
        this.limit += 20;

        axios
          .post(this.domain + "/api/getChatMessages", {
            auth_token: this.auth_token,
            id: this.user.userid,
            limit: this.limit,
          })
          .then((response) => {
            if (response.data.status != "Success") {
              this.$store.dispatch("setMessage", {
                type: "error",
                text: "Can't fetch messages or no chat messages found",
              });
            } else {
              let nextMessages = response.data.results;
              if (nextMessages !== null) {
                nextMessages.forEach(function(message) {
                  that.onNewMessageAdded(message, false);
                });
              }
            }
            this.loading = false;
          })
          .catch((error) => {
            //message send error
            console.log(error);
          });
      }
    },
    scrollToEnd() {
      this.$nextTick(() => {
        var container = this.$el.querySelector(".chat-container");
        container.scrollTop = container.scrollHeight;
      });
    },
    scrollTo() {
      this.$nextTick(() => {
        let currentHeight = this.$refs.chatContainer.scrollHeight;
        let difference = currentHeight - this.totalChatHeight;
        var container = this.$el.querySelector(".chat-container");
        container.scrollTop = difference;
      });
    },
    addEmojiToMessage(emoji) {
      this.messageContent += emoji.data;
    },
    toggleEmojiPanel() {
      this.emojiPanel = !this.emojiPanel;
      this.scrollToEnd();
    },
    showCamera() {
      return true;
    },
    onDismissed() {
      this.$store.dispatch("clearMessage");
    },
  },
};
</script>

<style>
.scrollable {
  overflow-y: auto;
  height: 90vh;
}
.typer {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  bottom: 0;
  height: 4.7rem;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 -5px 10px -5px rgba(0, 0, 0, 0.2);
}
.typer input[type="text"] {
  position: absolute;
  left: 3.8rem;
  padding: 1rem;
  width: 80%;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1.25rem;
}
.emojiBtn {
  margin-left: 20px;
}
.chat-container {
  box-sizing: border-box;
  height: calc(100vh - 14rem);
  overflow-y: auto;
  padding: 10px;
}
.message {
  margin-bottom: 3px;
}
.message.own {
  text-align: right;
}
.message.own .content {
  background-color: #aaa;
  color: white;
}
.chat-container .username,
.chat-container .msgtime {
  font-size: 11px;
  color: #aaa;
}
.chat-container .msgdate {
  font-size: 13px;
  color: #424242;
  text-align: center;
}
.chat-container .content {
  padding: 8px;
  background-color: #fb8c00;
  font-size: 19px;
  color: white;
  border-radius: 4px;
  display: inline-block;
  max-width: 50%;
  word-wrap: break-word;
}
.loading {
  background: #ffffff;
  text-align: center;
  height: auto;
  padding: 30px 0;
}
#EmojiPicker {
  height: auto;
  max-height: -webkit-fill-available;
  width: 100% !important;
}
.container-emoji {
  max-height: 300px;
}
@media (max-width: 480px) {
  .chat-container .content {
    max-width: 80%;
  }
}
</style>
