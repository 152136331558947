<template>
  <div>
    <v-container>
      <v-layout row wrap>
        <v-flex xs12 class="text-xs-center">
          <chat></chat>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import Chat from "./Chat/Chat.vue";
export default {
  name: "Chat",
  components: {
    chat: Chat,
  },
};
</script>
